import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Spin, Typography, message, notification } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useCheckStatusMutation, useResendValidationLinkMutation } from '../../features/auth/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import Timer from '../../components/common/Timer';
import AuthLayout from '../Auth/Layout';

const VerifyAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { user_identification, user_email, is_authenticated, is_account_active } = useAuth();

  const [checkStatus, { isLoading: isLoadingCheckStatus }] = useCheckStatusMutation();
  const [resendEmailValidationLink, { isLoading: isResendLoading }] = useResendValidationLinkMutation();

  const [showTimer, setShowTimer] = useState(false);

  const handleRequestVerificationEmail = async () => {
    try {
      await resendEmailValidationLink(user_identification);
      setShowTimer(true);

      message.success(t('Register.Email is sent'));
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleCheckEmail = async () => {
    try {
      checkStatus(user_identification || location?.state?.user_identification)
        .unwrap()
        .then((res) => {
          if (!res.element.user_is_active) message.error({ content: 'Your email address is not verified' });
        })
        .catch((err: any) => {
          notification.error({ message: err.response.data.message || 'Email verification check failure' });
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    if (!is_authenticated || is_account_active) navigate('/');
  }, [is_account_active, is_authenticated, navigate]);

  return (
    <AuthLayout>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={3}>{t('Register.Verify your email').toUpperCase()}</Typography.Title>
        <Typography.Paragraph style={{ color: '#3D5249', fontSize: 18 }}>
          {t('Register.You will need to verify your email to complete registration')}
        </Typography.Paragraph>

        <img
          src="https://cdn.1cdn.app/application/LUMBERJACK/lumberjack_verify_email.svg"
          width={300}
          alt=""
          style={{ margin: '30px auto' }}
        />

        <Typography.Paragraph style={{ color: 'rgba(12,39,27,0.6)' }}>
          {t('Register.An email has been sent to')}{' '}
          <Typography.Text style={{ color: 'rgba(12,39,27,0.8)' }}>
            {user_email || location?.state?.user_email}
          </Typography.Text>{' '}
          {t(
            'Register.with a link to verify your account, if you have not received the email after a few minutes, please check your spam folder'
          )}
        </Typography.Paragraph>

        <Space wrap>
          <Button
            loading={isLoadingCheckStatus}
            disabled={isLoadingCheckStatus}
            style={{ backgroundColor: '#EE5253', color: '#fff', border: 'none' }}
            onClick={handleCheckEmail}
          >
            {t('Register.Next')}
          </Button>

          {showTimer ? (
            <Typography.Text disabled style={{ marginLeft: 10 }}>
              <Timer setShowTimer={setShowTimer} ttl={120} /> left
            </Typography.Text>
          ) : (
            <Button type="dashed" onClick={handleRequestVerificationEmail}>
              {isResendLoading ? <Spin /> : t('Register.Resend email')}
            </Button>
          )}
        </Space>
      </div>
    </AuthLayout>
  );
};

export default VerifyAccount;
